<template>
  <page-list>
    <template #header>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px" inline="true">
        <el-form-item label="所属门店：" prop="shopId">
          <el-select
            v-model="form.shopId"
            placeholder="请选择"
            clearable
            filterable
          >
            <el-option
              v-for="(item, index) in shopOptions"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList()">查 询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="
          editMode = 'add';
          dialogVisible = true;
        "
          >添 加</el-button>
        </el-form-item>
      </el-form>
    </template>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      stripe
      v-loading="loading"
      element-loading-background="transparent"
      @sort-change="sortChangeHandle"
      @row-dblclick="editHandle"
      row-key="id"
    >
      <el-table-column
        prop="id"
        label="ID"
        min-width="40px"
        header-align="center"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="shopId"
        label="可用店铺"
        header-align="center"
        align="center"
        min-width="120px"
        show-overflow-tooltip
      >
        <template v-slot="scope">
          <template v-if="scope.row.shopId.split(',').includes('0')"
            >全部</template
          >
          <template v-else>
            <div
              class="tag-item"
              type="success"
              :alt="item"
              v-for="(item, index) in scope.row.shopId.split(',')"
              :key="index"
            >
              {{ getShopName(item, scope.row.shopId.split(",")) }}
            </div>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        prop="image"
        label="图片"
        header-align="center"
        align="center"
        min-width="60px"
      >
        <template v-slot="scope">
          <el-image
            v-if="scope.row.image"
            style="width: 30px; height: 30px"
            :src="scope.row.image.split(',')[0]"
            :preview-src-list="scope.row.image.split(',')"
            preview-teleported
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="title"
        label="优惠券名称"
        min-width="100px"
        header-align="center"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="switch"
        label="是否上架"
        header-align="center"
        align="center"
        min-width="80px"
      >
        <template v-slot="scope">
          <el-switch
            v-model="scope.row.switchFlag"
            active-color="#18bc9c"
            inactive-color="#d2d6de"
            :active-value=1
            :inactive-value=0
            @change="statusChangeHandle(scope.row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        prop="least"
        label="消费多少可用"
        header-align="center"
        align="center"
        min-width="110px"
        :formatter="(row) => Number(row.least).toFixed(2)"
      >
      </el-table-column>
      <el-table-column
        prop="value"
        label="优惠券金额"
        header-align="center"
        align="center"
        min-width="110px"
        :formatter="(row) => Number(row.value).toFixed(2)"
      >
      </el-table-column>
      <el-table-column
        prop="receive"
        label="已领"
        header-align="center"
        align="center"
        min-width="80px"
      >
      </el-table-column>
      <el-table-column
        prop="surplus"
        label="剩余数量"
        header-align="center"
        align="center"
        min-width="80px"
      >
      </el-table-column>
      <el-table-column
        prop="distribute"
        label="发行数量"
        header-align="center"
        align="center"
        min-width="80px"
      >
      </el-table-column>
      <el-table-column
        prop="limit"
        label="每人限领数"
        header-align="center"
        align="center"
        min-width="100px"
      >
      </el-table-column>
      <el-table-column
        prop="exchangeCode"
        label="兑换码"
        header-align="center"
        align="center"
        min-width="80px"
      >
      </el-table-column>
      <el-table-column
        prop="type"
        label="使用类型"
        header-align="center"
        align="center"
        min-width="80px"
      >
        <template v-slot="scope">
          <span
            class="fa fa-circle"
            :style="{ color: COUPON_USE_TYPE[scope.row.type].color }"
            >{{ " " + COUPON_USE_TYPE[scope.row.type].label }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="score"
        label="消耗积分"
        header-align="center"
        align="center"
        min-width="80px"
      >
      </el-table-column>
      <el-table-column
        prop="starttime"
        label="开始时间"
        header-align="center"
        align="center"
        min-width="150px"
        :formatter="
          (row) => $moment.unix(row.updatetime).format('yyyy-MM-DD HH:mm:ss')
        "
      >
      </el-table-column>
      <el-table-column
        prop="endtime"
        label="结束时间"
        header-align="center"
        align="center"
        min-width="150px"
        :formatter="
          (row) => $moment.unix(row.updatetime).format('yyyy-MM-DD HH:mm:ss')
        "
      >
      </el-table-column>
      <el-table-column
        prop="createtime"
        label="创建时间"
        header-align="center"
        align="center"
        min-width="150px"
        :formatter="
          (row) => $moment.unix(row.createtime).format('yyyy-MM-DD HH:mm:ss')
        "
      >
      </el-table-column>
      <el-table-column
        prop="weigh"
        label="权重"
        header-align="center"
        align="center"
        min-width="80px"
      >
      </el-table-column>
      <el-table-column
        label="操作"
        header-align="center"
        align="center"
        fixed="right"
        min-width="80"
      >
        <template v-slot="scope">
          <el-button
            v-if="scope.row.switchFlag === 1"
            class="icon-btn distribute-btn fa fa-ticket"
            @click="distributeHandle(scope.row)"
            >发券</el-button
          >
          <el-button
            class="icon-btn fa fa-pencil"
            type="primary"
            @click="editHandle(scope.row)"
          >
            <!-- 编辑 -->
          </el-button>
          <el-button
            class="icon-btn fa fa-trash"
            type="danger"
            @click="delHandle(scope.row)"
          >
            <!-- 删除 -->
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-model="paginationData"
      :total="total"
      @change="getList"
    ></pagination>

    <EditDialog
      v-model="dialogVisible"
      @refresh="getList"
      :editData="editData"
      :mode="editMode"
    ></EditDialog>

    <Distribute
      v-model="distributeVisible"
      :couponData="couponData"
    ></Distribute>
  </page-list>
</template>

<script>
import EditDialog from "./editDialog";
import Distribute from "./distribute.vue";
import { getShopList } from "@/apis/auth";
import { getShopOptions } from "@/apis/unidrink/shop";
import {
  getCouponList,
  deleteCoupon,
  toggleCoupon,
} from "@/apis/unidrink/coupon";
import { cloneDeep } from "lodash";
import { msgErr, msgSuc } from "@/helpers/message";
import { COUPON_USE_TYPE } from "@/helpers/constants";

export default {
  components: { EditDialog, Distribute },
  data() {
    return {
      COUPON_USE_TYPE: COUPON_USE_TYPE,
      tableData: [],
      paginationData: {},
      initForm: {
        sort: "weigh",
        order: "desc",
      },
      form: {},
      total: 0,
      loading: false,
      dialogVisible: false,
      editMode: "add",
      editData: {},
      shopOptions: [],
      distributeVisible: false,
      couponData: {},
    };
  },
  created() {},
  mounted() {
    getShopOptions(this.shopOptions);
  },
  activated() {
    this.form = cloneDeep(this.initForm);
    this.getList();
  },
  methods: {
    getShopName(shopId) {
      const find = this.shopOptions.find((item) => {
        return item.id === Number(shopId);
      });

      return find ? find.name : "";
    },
    getList() {
      this.loading = true;
      if(this.form.shopId == ""){
        delete this.form.shopId;
      }
      getCouponList({ ...this.form, ...this.paginationData })
        .then(({ code, result, message }) => {
          if (code !== "0000") {
            msgErr(message);
            return;
          }
          const { list, total } = result;
          this.tableData = list;
          this.total = total;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    sortChangeHandle({ prop, order }) {
      if (prop && order) {
        this.form = {
          sort: prop,
          order: order === "descending" ? "desc" : "asc",
        };
      } else {
        this.form = cloneDeep(this.initForm);
      }
      this.getList();
    },

    delHandle(row) {
      this.$confirm("确定删除此项？", "温馨提示").then(() => {
        deleteCoupon(row.id).then(({ code, message }) => {
          if (code === "0000") {
            msgSuc("操作成功！");
            this.getList();
          } else {
            msgErr(message);
          }
        });
      });
    },

    editHandle(row) {
      this.editMode = "edit";
      this.dialogVisible = true;
      this.editData = row;
    },

    distributeHandle(row) {
      this.distributeVisible = true;
      this.couponData = row;
    },

    statusChangeHandle(row) {
      toggleCoupon(row.id, row.switchFlag)
        .then(({ code, message }) => {
          if (code === "0000") {
            msgSuc("操作成功！");
          } else {
            msgErr(message);
          }
        })
        .finally(() => {
          this.getList();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.tag-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.move-handle {
  background-color: #2c3e50;
  color: #ffffff;
}

.distribute-btn {
  background-color: #3498db;
  color: #ffffff;
  width: 60px;
}
</style>
